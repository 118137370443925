<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <h3 class="text-center tx-color-01 mg-b-5">Forgot Password?</h3>
      <p class="text-center tx-color-03 tx-16 mg-b-40">Enter the {{ getLoginFormLabel().toLowerCase() }} for your account below</p>
      <form v-on:submit.prevent>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="d-flex justify-content-between mg-b-5">
                <label class="mg-b-0-f" for="inpEmail">{{ getLoginFormLabel() }}</label>
                <router-link :to="{name: 'Login'}"
                             class="tx-13"
                >Login
                </router-link>
              </div>
              <div class="input-group">
                <input
                    id="inpEmail"
                    class="form-control"
                    :type="inputType"
                    :autocomplete="autocomplete"
                    v-model="email"
                    tabindex="2"
                    placeholder="yourname@yourmail.com"
                />
              </div>
              <ul v-if="errors.length > 0" class="pl-0 my-2" style="list-style: none;">
                <li v-for="(error, index) in errors" :key="index">
                  <span class="text-danger">{{ error }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-brand-02 btn-block"
                    @click="setPassword"
                    :disabled="loading || sent"
                    tabindex="4">
              <send-icon />
              Send Password Reset Email&nbsp;
              <span v-if="loading"><i class="fa fa-spinner fa-spin fa-2x"></i></span>
            </button>
          </div>
        </div>
        <div v-if="sent" class="row mt-2">
          <div class="col">
            <p class="text-success">A password reset email has been sent, if an account exists with that email address</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import {SendIcon} from "vue-feather-icons";
import ApiService from "@/services/api.service";
import FeaturesMixin from '@/mixins/features.mixin';

export default {
  name: 'ForgotPassword',
  components: {
    SendIcon
  },
  data() {
    return {
      loading: false,
      errors: [],
      email: '',
      sent: false,
      inputType: this.getLoginFormInputType(),
      autocomplete: this.getLoginFormAutocomplete(),
    }
  },
  props: {
    userEmail: {
      type: String,
      default: ''
    }
  },
  mixins: [FeaturesMixin],
  methods: {
    validateInputs() {
      // Clear error text
      this.errors = [];

      if (this.email.length === 0) {
        this.errors = ['Please enter your account email'];
        return false;
      }

      const re = /^[^\s@]+@[^\s@]+$/;
      if (!this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG) && !re.test(String(this.email).toLowerCase())) {
        this.errors = ['Please enter a valid email'];
        return false;
      }

      return true;
    },
    async setPassword() {
      this.loading = true;
      if (!this.validateInputs()) {
        this.loading = false;
        return;
      }
      const { data } = await ApiService.post('sendPasswordReset', {email: this.email});
      this.loading = false;
      if (data === undefined) {
        this.errors = ["An unexpected error occurred while trying to send your password reset email, please try again later"];
      } else if (data.success) {
        this.sent = true;
      } else {
        this.errors = data.errors;
      }
    },
  },
  mounted() {
    this.email = this.userEmail;
  }
}
</script>
