<template>
  <div class="coverage-included-perils">
    <table v-if="!starrMode || !propertyCoverageDocument" class="table table-striped table-hover">
      <thead>
      <tr><th>Peril</th><th>Any One Limit</th><th>Annual Aggregate Limit</th><th>Deductible</th></tr>
      </thead>
      <tbody>
      <tr v-for="(peril, index) in perils" :key="index">
        <td>{{ peril.peril_caption }}
          <br />
          <button class="btn btn-xs btn-link" @click="$emit('perilselected', peril.coverage_document_detail_id)">
            <book-open-icon size="1x" />&nbsp;View Definition
          </button>
        </td>
        <td>{{ formatThousandsMixin(peril.dec_any_one_limit) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_annual_aggregate_limit) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_deductible) }}</td>
        <!--                <td>{{ peril.peril_definition }}</td>-->
      </tr>
      </tbody>
    </table>
    <table v-else-if="starrMode && !complex" class="table table-striped table-hover">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th colspan="4" class="tableHeaderTitle">
          Sublimit Information
        </th>
        <th></th>
        <th colspan="4" class="tableHeaderTitle">Deductibles</th>
      </tr>
      <tr>
        <th>Peril</th>
        <th>Currency</th>
        <th>Period</th>
        <th>Unit</th>
        <th>Occurrence Limit</th>
        <th>Aggregate Limit</th>
        <th></th>
        <th>Waiting Period (Days)</th>
        <th>Percentage</th>
        <th>Minimum Deductible</th>
        <th>Maximum / Fixed</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(peril, index) in perils" :key="index">
        <td>{{ peril.peril_caption }}
          <br />
          <button class="btn btn-xs btn-link" @click="$emit('perilselected', peril.coverage_document_detail_id)">
            <book-open-icon size="1x" />&nbsp;View Definition
          </button>
        </td>
        <td>{{ currency }}</td>
        <td>{{ peril.peril_sublimit_period }}</td>
        <td>
          {{ peril.peril_sublimit_period_unit }}
          <br />
          <strong>Not to exceed</strong>
        </td>
        <td>{{ formatThousandsMixin(peril.dec_any_one_limit) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_annual_aggregate_limit) }}</td>
        <td></td>
        <td>{{ formatThousandsMixin(peril.deductible_waiting_period_days) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_deductible_pc) }}%</td>
        <td>{{ formatThousandsMixin(peril.dec_deductible_min) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_deductible) }}</td>
      </tr>
      </tbody>
    </table>
    <table v-else-if="starrMode && complex" class="table table-striped table-hover">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th colspan="3" class="tableHeaderTitle">
          Sublimit Information
        </th>
        <th></th>
        <th colspan="3" class="tableHeaderTitle">Deductibles</th>
        <th></th>
      </tr>
      <tr>
        <th>Peril</th>
        <th>Currency</th>
        <th>Period</th>
        <th>Unit</th>
        <th v-if="coverageDocument.flag_policy_layers_applicable==0">
          PO Share
        </th>
        <th>Overall Limit</th>
        <th></th>
        <th>Waiting Period (Days)</th>
        <th>Value ({{ currency }})</th>
        <th>Percentage</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(peril, index) in perils" :key="index">
        <td>{{ peril.peril_caption }}
          <br />
          <a @click="$emit('perilselected', peril.coverage_document_detail_id)" href="#">
            <small><book-open-icon size="1x" />&nbsp;View Definition</small>
          </a>
        </td>
        <td>{{ currency }}</td>
        <td>{{ peril.peril_sublimit_period }}</td>
        <td>
          {{ peril.peril_sublimit_period_unit }}
          <br />
          <strong>Not to exceed</strong>
        </td>
        <td v-if="coverageDocument.flag_policy_layers_applicable==0">
          {{ formatThousandsMixin(coverageDocument.dec_po_share_pc) }}
        </td>
        <td>{{ formatThousandsMixin(peril.dec_annual_aggregate_limit) }}</td>
        <td></td>
        <td>{{ formatThousandsMixin(peril.deductible_waiting_period_days) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_deductible) }}</td>
        <td>{{ formatThousandsMixin(peril.dec_deductible_pc) }}%</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {BookOpenIcon} from 'vue-feather-icons';
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
const POLICY_CLASS_TYPE_PROPERTY = 4;

export default {
  name: "CoverageIncludedPerils",
	mixins: [FeaturesMixin],
  components: {
    BookOpenIcon
  },
  props: {
    coverageDocument: {
      required: true,
      type: Object
    },
    perils: {
      required: true,
      type: Array
    },
    complex: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: 'EUR'
    }
  },
  computed: {
    starrMode() {
      return process.env.VUE_APP_ICEDE_CLIENT === 'STARR';
    },
    propertyCoverageDocument() {
      return this.coverageDocument.peril_class_id == POLICY_CLASS_TYPE_PROPERTY;
    }
  },
  data: () => ({
    selectedPerilId: 0,
  }),
}
</script>
<style>
.tableHeaderTitle {
  background-color: #eff0f3;
  border: 1px solid #e5e5e5 !important;
  border-bottom: none !important;
}
</style>
