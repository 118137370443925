<template>
	<div>
		<card>
			<b-button-toolbar key-nav aria-label="Toolbar with button groups">
				<b-button-group class="mx-1" v-for="(group, letter) in groupedCountries" :key="letter">
					<b-button @click="selectGroup(letter)" :variant="selectedGroup === letter ? 'primary' : 'secondary'">{{ letter }}</b-button>
				</b-button-group>
			</b-button-toolbar>

			<b-form-input class="mt-4" v-model="searchQuery" placeholder="Search country or issuing office"></b-form-input>

			<b-table class="mt-4" v-if="selectedGroup" striped responsive hover :items="expandedCountries" :fields="fields">
				<template #cell(flag)="data">
					<img v-if="getImageSrc(data.item.img_flag_path)" :src="getImageSrc(data.item.img_flag_path)" :alt="data.item.country_name" :width="50"/>
				</template>
				<template #cell(view)="data">
					<router-link class="btn btn-brand-02" :to="{
                      name: 'Country Data',
                      params: {companyId: data.item.issuingOffice.company_id},
                    }">
						View Country Data
					</router-link>
				</template>
			</b-table>
		</card>
	</div>
</template>
<script>
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
import FormatterMixin from "@underwriters/src/mixins/formatter.mixin";

export default {
	name: "Wiki",
	data: () => {
		return {
			countryList: [],
			selectedGroup: null,
			fields: [{ key: 'flag', label: '', thStyle: { width: "7%" }}, { key: 'country_name', label: 'Country' }, { key: 'issuingOffice.regional_company_name', label: 'Office' }, { key: 'View', label: 'View' }],
			searchQuery: ''
		};
	},
	computed: {
		groupedCountries() {
			const grouped = {};
			this.countryList.forEach(country => {
				const firstLetter = country.country_name.charAt(0).toUpperCase();
				if (!grouped[firstLetter]) {
					grouped[firstLetter] = [];
				}
				grouped[firstLetter].push(country);
			});
			// Sort the groups alphabetically
			const sortedGroups = {};
			Object.keys(grouped).sort().forEach(key => {
				sortedGroups[key] = grouped[key];
			});
			return sortedGroups;
		},
		filteredCountries() {
			let filtered = this.countryList;
			if (this.searchQuery) {
				const query = this.searchQuery.toLowerCase();
				filtered = filtered.filter(country =>
					country.country_name.toLowerCase().includes(query) ||
					country.issuing_offices.some(office => office.regional_company_name.toLowerCase().includes(query))
				);
			}
			if (this.selectedGroup && !this.searchQuery) {
				filtered = filtered.filter(country => country.country_name.charAt(0).toUpperCase() === this.selectedGroup);
			}
			return filtered;
		},
		expandedCountries() {
			const expanded = [];
			this.filteredCountries.forEach(country => {
				country.issuing_offices.forEach(office => {
					expanded.push({ ...country, issuingOffice: office });
				});
			});
			return expanded;
		}
	},
	mixins: [FeaturesMixin, FormatterMixin],
	methods: {
		async getCountryList() {
			this.countryList = this.$store.getters.countriesWithOffices;
		},
		selectGroup(letter) {
			this.selectedGroup = letter;
			if (!this.searchQuery) {
				this.filterCountries();
			}
		},
		filterCountries() {
			if (!this.selectedGroup) {
				this.selectedGroup = null;
			}
		},
		getImageSrc(imgPath) {
			let imagePath;
			try {
				imagePath = require(`@/assets/img/default/country-flags/${imgPath}`);
			} catch (error) {
				try {
					imagePath = require(`@/assets/img/default/country-flags/lg/${imgPath}`);
				} catch (error) {
					imagePath = null;
				}
			}
			return imagePath;
		}
	},
	mounted() {
		this.getCountryList();
		this.selectedGroup = 'A';
	}
};
</script>
